<template>
  <div style="display: flex">
    <navigation index="storeHome">
      <div style="width: 100%">
        <el-card
          class="box-card"
          style="
            margin-top: 100px;
            max-width: 90%;
            margin: 0 auto;
            border: 1px solid var(--el-menu-border-color);
          "
        >
          <template #header>
            <span>我的工作</span>
          </template>

          <el-row style="padding: 20px; text-align: center">
            <el-col :span="6">
              <!-- <el-countdown title="当前时间" format="HH:mm:ss" :value="Time" /> -->
              <el-statistic title="未完成" :value="BusinessVolume.OrderCount" />
            </el-col>
            <el-col :span="6">
              <el-statistic
                title="已完成"
                :value="BusinessVolume.DayOrderTotal"
              />
            </el-col>
            <el-col :span="6">
              <el-statistic
                title="单量"
                :value="BusinessVolume.PartOrderTotal"
              />
            </el-col>
            <el-col :span="6">
              <el-statistic title="投诉" :value="BusinessVolume.OrderTotal" />
            </el-col>
          </el-row>
        </el-card>

        <br />
        <el-card
          class="box-card"
          style="
            margin-top: 100px;
            max-width: 90%;
            margin: 0 auto;
            border: 1px solid var(--el-menu-border-color);
          "
        >
          <template #header>
            <span> 我的信息 </span>
          </template>
          <div id="main" style="width: 100%">
            <el-form
              ref="form"
              :model="UserInfo"
              label-width="120px"
              style="margin: 10px 0px"
            >
              <el-row>
                <el-col :span="8">
                  <el-form-item label="头像">
                    <el-upload
                      class="avatar-uploader"
                      :action="UploadPictureImageUrl"
                      :show-file-list="false"
                      :on-success="UploadProductSuccess"
                      :before-upload="UploadProductSuccess"
                      :headers="headersImage"
                    >
                      <img
                        v-if="UserInfo.ProfilePhotoFormat"
                        :src="UserInfo.ProfilePhotoFormat"
                        class="avatar"
                      />
                      <el-icon v-else class="avatar-uploader-icon"
                        ><Plus
                      /></el-icon>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="姓名">
                    <el-input v-model="UserInfo.Name"></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="性别">
                      <el-input v-model="UserInfo.Name"></el-input>
                    </el-form-item> -->
                  <el-form-item label="邮箱">
                    <el-input v-model="UserInfo.Email"></el-input>
                  </el-form-item>
                  <el-form-item label="联系方式">
                    <el-input v-model="UserInfo.Phone"></el-input>
                  </el-form-item>
                  <el-form-item label="修改密码">
                    <el-input v-model="UserInfo.Password"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div style="width: 100%; text-align: center">
                <el-button
                  style="width: 150px; height: 40px"
                  @click="UpdateUserInfo"
                  type="primary"
                  >保存</el-button
                >
              </div>
            </el-form>
          </div>
        </el-card>
      </div>
    </navigation>
  </div>
</template>
  <script>
import axios from "../../commons/AxiosMethod.js";
import navigation from "../../components/Modules/CompanyNavigation.vue";
import { ElMessageBox, ElMessage, UploadProps } from "element-plus";
import { CloseBold, Plus, Delete } from "@element-plus/icons-vue";
import * as echarts from "echarts";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      BusinessVolume: {
        OrderTotal: "",
        PartOrderTotal: "",
        DayOrderTotal: "",
      },
      BusinessVolumeWhere: {},
      OrderCount: {},
      TrendBusinessVolumeWhere: {},
      TrendBusinessVolume: {},
      UserInfo: {},

      headersImage: {},
      UploadPictureImageUrl: "",
    };
  },
  name: "App",
  mounted() {
    this.GetMyUserInfo();
 
    this.UploadPictureImageUrl = axios.GetUrl() + "/api/Upload/ImageFileWebp";
    this.UploadFullImageFileUrl =
      axios.GetUrl() + "/api/Upload/FullImageFileWebp";

    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };
  },

  components: {
    navigation,
    Plus,
  },
  methods: {
  
    //获取登录用户的信息
    GetMyUserInfo() {
      axios.apiMethod("/Companys/User/GetMyUserInfo", "get", {}, (result) => {
        this.UserInfo = result.Data;
      });
    },
    UploadProductSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.UserInfo.ProfilePhotoFormat = response.Data.ImageFormet;
        this.UserInfo.ProfilePhoto = response.Data.FileName;
      }
    },

    // 修改个人信息
    UpdateUserInfo() {
      axios.apiMethod(
        "/Companys/User/UpdateUserInfo",
        "post",
        this.UserInfo,
        (result) => {
          if (result.Data.IsSuccess) {
            ElMessage({
              title: "提示",
              message: "操作成功",
              type: "success",
            });
          }
        }
      );
    },
  },
};
</script>
  
  <style scoped>
.flex-grow {
  flex-grow: 1;
}

.avatar-uploader .avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.avatar-uploader-icon {
  border: 1px dashed #666;
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  text-align: center;
}
</style>
  